import * as React from "react";
import { Component } from 'react';
import Layout from '../../components/global/layout.js'
import Content from '../../components/testing/content.js'
import '@fortawesome/fontawesome-free/css/all.css'; 
import '../../sass/app.scss';

class Testing extends Component {
  render() {
    return (
      <>
        <Layout section="hiv-landscape" page="testing">
          <Content section="hiv-landscape" page="testing" />
        </Layout>
      </>
    )
  }
}

export default Testing;