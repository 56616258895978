import * as React from "react";
import { Component } from "react";
import HTML5Video from "../global/video.js";
import BackToTop from "../global/BackToTop.js";
import { Container, Row, Col } from "react-bootstrap";
import OptInOutDesktop from "../../images/hiv-testing_outside-deskop.jpg";
import OptInOutMobile from "../../images/hiv-testing_outside-mobile.jpg";
import References from "../global/references.js";

class Content extends Component {
  constructor() {
    super();
  }
  render() {
    return (
      <div className={`${this.props.show ? "d-block" : "d-none"}`}>
        <Container
          fluid
          className="hero testing"
          role="img"
          aria-label="Close up of three people
                      looking into the camera"
        />
        <Container className="testing">
          <Row>
            <Col xs={10} className="m-auto px-0">
              <div className="hero-callout muted-yellow-dark">
                
                <h1 className="title ">
                  <span className="sub-title d-block">HIV Testing</span>
                  A Priority for Helping End the HIV Epidemic
                </h1>
              </div>
              <p className="pt-2">
                In many ways, HIV testing should be the cornerstone of all
                efforts to help end the epidemic in the United States. It is the
                first step in getting people diagnosed and then linked to care,
                and it provides an important opportunity to discuss prevention
                options with people at risk for HIV.<sup>1</sup> Unfortunately,
                we know that in 2021, it was estimated that only 35% of people
                in the US had ever been tested for HIV,* and in 2021, an
                estimated 1 in 8 people living with HIV in the US was unaware of
                their status.
                <sup>2,3</sup>
              </p>
              <p>
                <em>
                  Everyone knowing their status is an important part of helping
                  end the HIV epidemic. It allows people to move forward and
                  talk to a healthcare provider about next steps.
                </em>
              </p>
              <p class="footnote"><span className="sup">*</span>These data represent 2021 weighted estimates of HIV testing
                prevalence by selected demographic characteristics, based on
                responses to the Behavioral Risk Factor Surveillance System
                (BRFSS) survey question, "Have you ever been tested for HIV?"
                <sup>2</sup></p>
              <h2 className="title">Recommendations for HIV Testing</h2>
              <p>
                The Centers for Disease Control and Prevention (CDC) recommends
                that everyone ages 13 to 64 get tested for HIV at least once in
                their lifetime, and that people at increased risk for HIV get
                tested more often—each year or even more frequently (every 3 to
                6 months, for example)—depending on their relationships and
                behavior.<sup>1</sup> Among other criteria, regular HIV testing
                is recommended for:
              </p>
              <ul className="indented-list">
                <li>
                  Men who have sex with men (MSM)<sup>1</sup>
                </li>
                <li>
                  People who have had more than 1 sexual partner since their
                  last HIV test<sup>1</sup>
                </li>
                <li>
                  Transgender people who have sex with men<sup>4,5</sup>
                </li>
                <li>
                  People who have had a sexually transmitted infection (STI)
                  <sup>1</sup>
                </li>
                <li>
                  People who use injection drugs<sup>1</sup>
                </li>
                <li>
                  People whose sexual partners fall into any of these categories
                  <sup>1</sup>
                </li>
              </ul>
              <p>
                It is also important that pregnant women talk to their
                healthcare provider about getting tested for HIV. Testing as
                soon as possible in pregnancy can help expectant mothers who are
                HIV positive receive the care they need to prevent
                mother-to-child transmission of HIV.<sup>1</sup>
              </p>
              <h2 className="title">
                Many People Are Not Getting Tested for HIV
              </h2>
              <p>
                Data suggest, however, that many people are not following these
                HIV testing guidelines.
              </p>
              <div className="white-box">
                <ul>
                  <li>
                    In <strong>2021</strong>, it was estimated that only{" "}
                    <strong>35%</strong> of people in the US had ever been
                    tested for HIV<sup>2,</sup>*
                  </li>
                  <li>
                    One study found that only <strong>4%</strong> of those with
                    commercial insurance and <strong>5.5%</strong> of those with
                    Medicaid received testing for HIV in <strong>2019</strong>
                    <sup>6</sup>
                  </li>
                  <li>
                    From <strong>2016</strong> to <strong>2017</strong>, data
                    showed that less than <strong>30%</strong> of people in the
                    US who were most at risk for acquiring HIV were tested in
                    the prior year<sup>7</sup>
                  </li>
                  <li>
                    From <strong>2016</strong> to <strong>2017</strong>, data
                    showed that only <strong>26%</strong> of people in the US
                    recommended for annual HIV testing in 7 states with a
                    disproportionate rural HIV burden were tested in the prior
                    year<sup>7</sup>
                  </li>
                  <li>
                    A US survey conducted between <strong>2006</strong> and{" "}
                    <strong>2016</strong> showed that among adults aged 18 and
                    older who were ever tested for HIV, the median time since
                    their last test was nearly <strong>3</strong> years
                    <sup>7</sup>
                  </li>
                </ul>
              </div>
              <p className="strong-red">
                In 2021, it was estimated that only <strong>27%</strong> of
                young people aged 18 to 24 in the US had ever been tested for
                HIV compared to an estimated&nbsp;
                <strong>48%</strong> of those aged 25 to 34,&nbsp;
                <strong>52%</strong> of those aged 35 to 44,&nbsp;
                <strong>47%</strong> of those aged 45 to 54,&nbsp;
                <strong>36%</strong> of those aged 55 to 64, and{" "}
                <strong>18%</strong> of those aged 65 and older.<sup>2,</sup>*
                In 2021, it was also estimated that <strong>37%</strong> of men
                and <strong>38%</strong> of women in the US had ever been tested
                for HIV.<sup>2,</sup>*&nbsp; The CDC analyzed data from the
                General Social Survey between 2006 and 2016 and revealed more
                information about who gets tested and how often:
              </p>
              <div className="white-box">
                <ul>
                  <li>
                    A higher percentage of Black Americans (<strong>57%</strong>
                    ) reported having ever been tested for HIV compared to that
                    of Hispanic/Latino Americans (<strong>47%</strong>) or White
                    Americans (<strong>35%</strong>)<sup>8</sup>
                  </li>
                  <li>
                    Despite higher rates of HIV in the Southern US, people there
                    were generally no more likely to report having ever been
                    tested (<strong>42%</strong> compared to{" "}
                    <strong>43%</strong> in the West, <strong>38%</strong> in
                    the Northeast, and <strong>33%</strong> in the Midwest)
                    <sup>8,9</sup>
                  </li>
                  <li>
                    <strong>71%</strong> of men in the US who had a recent male
                    sex partner reported having ever been tested for HIV, but
                    only <strong>42%</strong> had been tested within the past 12
                    months<sup>8</sup>
                  </li>
                  <li>
                    <strong>58%</strong> of men in the US with other recent
                    HIV-related risk behaviors (such as multiple female sex
                    partners) reported having ever been tested for HIV, but only{" "}
                    <strong>37%</strong> had been tested within the past 12
                    months<sup>8</sup>
                  </li>
                  <li>
                    <strong>66%</strong> of women in the US with recent
                    HIV-related risk behaviors reported having ever been tested
                    for HIV, but only <strong>46%</strong> had been tested
                    within the past 12 months<sup>8</sup>
                  </li>
                </ul>
              </div>
              <p class="footnote">
              <span className="sup">*</span>These data represent 2021 weighted estimates of HIV testing
                prevalence by selected demographic characteristics, based on
                responses to the Behavioral Risk Factor Surveillance System
                (BRFSS) survey question, "Have you ever been tested for HIV?"
                <sup>2</sup>
              </p>
              <h2 className="title">Barriers to HIV Testing</h2>
              <p>
                In the early days of HIV, there were few treatment options, and
                a positive test result was seen as a death sentence. Things have
                changed dramatically since then, and with the proper care,
                people can live with HIV for many years.<sup>10,11</sup> In
                order to receive such care, however, people must first get
                tested for HIV.<sup>1</sup> Research has shown that fear and
                stigma as well as perceived cost and perceived lack of risk
                prevent many people from seeking the HIV testing they need.
                <sup>12</sup>
              </p>
              <p>
                One study published in 2019 asked men and women aged 19 to 64
                living in the Southern US why they hadn’t been tested for HIV
                and had them rank possible reasons. Concerns about cost and
                insurance reimbursements were ranked highest followed by not
                knowing where to receive HIV care and not feeling at risk for
                HIV. Fear of the testing procedure and the results were also on
                the list. Some of the other barriers identified in the study
                highlight the stigma that still surrounds both HIV and HIV
                testing. Participants feared that HIV testing reflected poorly
                on them as a person, that they’d be judged by their healthcare
                provider, and that other people would find out about the test.
                <sup>12</sup>
              </p>
              <p>
                A 2017 survey of young people aged 18 to 30 in the US found
                similar results. Of those surveyed, more than half (54%) had
                never been tested for HIV. When asked why, 67% of those not
                tested said they didn’t think they were at risk for HIV, and 41%
                said a doctor had never suggested HIV testing. Other reasons for
                not getting tested included feeling embarrassed, not knowing
                where to go, cost or privacy concerns, and fear of the test
                results.<sup>13</sup>
              </p>
              <p>
                A separate US survey from 2014 to 2015 of people over the age of
                18 found that many (65%) would like their physician to recommend
                HIV testing, and 85% said they would be “likely or very likely”
                to accept an HIV test if their physician offered it.
                <sup>14</sup>
              </p>
              <div className="white-box">
                <div className="chart-bg">
                  <div className="cont-chart testing">
                    <h3>
                      HIV TESTING RATES IN <br className="d-block d-md-none" />
                      THE US BY AGE, 2021*
                    </h3>
                    <div className="chart-area">
                      <div className="cont-img">
                        <img
                          className="d-none d-lg-block w-100"
                          src={"/hiv-testing-in-us-by-age.svg"}
                          alt="Bar Graph Showing HIV Testing in the US By Age, 2018"
                        />
                        <img
                          className="d-block d-md-none w-100"
                          src={"/hiv-testing-in-us-by-age-mobile.svg"}
                          alt="Bar Graph Showing HIV Testing in the US By Age, 2018"
                        />
                      </div>
                      <p className="chart-text">
                        Only an estimated{" "}
                        <span className="font-red font-heavy">27%</span>
                        &nbsp;of young people in the us aged 18 to 24 had ever
                        been tested for hiv in{" "}
                        <span className="font-red font-heavy">2021</span>.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="footnote w-75-mobile">
                  Source: AIDSVu. HIV Testing, 2021.&nbsp;
                  <a
                    target="_blank"
                    href="https://aidsvu.org/local-data/united-states/"
                    /* data-analytics-event-category="External Links"
                    data-analytics-event-label="Click on Deeper Look: HIV Testing Link" */
                    className=""
                  >
                    <span>Local Data: United States</span>
                  </a>
                  . Data available through the CDC Behavioral Risk Factor
                  Surveillance System (BRFSS).
                </div>
              </div>
              <p class="footnote">
              <span className="sup">*</span>These data represent 2021 weighted estimates of HIV testing
                prevalence by selected demographic characteristics, based on
                responses to the Behavioral Risk Factor Surveillance System
                (BRFSS) survey question, "Have you ever been tested for HIV?"
                <sup>2</sup>
              </p>
              <h2 className="title pt-4">Cost of HIV Testing</h2>
              <p>
                Though the perceived cost of HIV tests is often cited as a
                barrier, most people should be able to get tested for little or
                no cost.<sup>12</sup> The Affordable Care Act requires insurance
                companies to cover HIV testing with no co-pay, and both Medicaid
                and Medicare cover some HIV testing. In addition, many
                community-based organizations and health departments will offer
                HIV testing for free or at very low cost to people who do not
                have insurance.<sup>1,15,16</sup> The CDC also covers the cost
                of an at-home HIV testing kit.<sup>17</sup>
              </p>
              <h2 className="title pt-4">Opt-In vs Opt-Out Testing Policies</h2>
              <p>
                Since 2006, the CDC has recommended that providers in all types
                of healthcare settings, including primary care settings,
                obstetric offices, public health and community-based clinics,
                hospitals, urgent care clinics, and emergency departments, offer
                HIV testing to all patients as part of standard care.
                <sup>18</sup> Under this kind of a policy, patients are
                told—verbally or through the forms or brochures they
                receive—that they will be given an HIV test unless they tell
                their healthcare provider they do not want to be tested. They do
                not need to give specific consent for the HIV test as it is
                considered part of the routine preventive care they are
                consenting to during the visit.<sup>19</sup> This is referred to
                as an “opt-out” policy.<sup>18</sup>
              </p>
              <p>
                In contrast, an “opt-in” policy asks patients deemed to be at
                risk if they’d like to be tested for HIV. The CDC believes that
                opt-in policies miss many people whose risk factors for HIV may
                be less obvious, such as heterosexual men and women who are
                unaware of their HIV risk, and those who live in non-urban,
                low-prevalence settings.<sup>19</sup> Research has also found
                that people may underestimate their own risk for HIV, and that
                fear of stigma and discrimination prevents many people from
                getting tested when given the option.<sup>20</sup>
              </p>
              <p className="mb-4 pb-1">
                By testing everyone, opt-out policies can also remove some of
                the stigma associated with HIV testing and can help lead to
                earlier diagnoses and treatment.<sup>19</sup> A 2022 systematic
                review found that opt-out testing can significantly improve HIV
                testing rates compared to opt-in testing in various settings and
                across different populations.<sup>21</sup>
              </p>
              <img
                className="d-none d-md-block w-85 mx-auto"
                src={OptInOutDesktop}
                alt="Man standing outside in an
                    urban area"
              />
              <img
                className="d-block d-md-none w-85 mx-auto"
                src={OptInOutMobile}
                alt="Man standing outside in an
                    urban area"
              />
              <h2 className="title">HIV Testing Settings</h2>
              <p>
                Today, people can find HIV testing across different settings.
                Many healthcare providers offer testing in their offices.
                Clinics, health departments, community-based health centers, and
                hospitals also offer HIV testing. HIV self-tests are also
                available at pharmacies and online, and some health departments
                and other organizations will send rapid self-tests to people’s
                homes at low or no cost.<sup>16</sup>
              </p>
              <p>
                Information about where to get tested can be found here:&nbsp;
                <br class="d-block d-lg-none" />
                <a
                  target="_blank" className="underline" style={{lineBreak: 'anywhere'}}
                  href="https://AIDSVu.org/services/#/testing"
                >
                  https://AIDSVu.org/services/#/testing
                </a>
              </p>
              <h2 className="title">Testing Procedures</h2>
              <p>
                HIV testing can be done with a saliva sample, a finger prick of
                blood, or a blood draw from a vein. Some HIV testing can also be
                done with a urine sample. Different types of HIV tests rely on
                different samples, and results take anywhere from 20 minutes to
                several days. In addition, some tests can detect HIV infection
                sooner after exposure than others; the time between exposure and
                being able to detect infection through a test is often called
                the “window period.”<sup>22</sup>
              </p>
              <h2 className="title">
                Different HIV Tests<sup>22</sup>
              </h2>
              <p>
                The type of HIV test a person gets is largely dependent on where
                they get tested, but understanding the differences may be
                helpful.
              </p>
              <Container>
                <Row>
                  <Col xs={12} className="px-0 mt-2 cont-testing-chart">
                    <div className="testing-chart first">
                      <div className="chart-column test-type">
                        <div className="header light">
                          Type <br className="d-block d-md-none" />
                          of Test
                        </div>
                        <div className="cell first">
                          <div className="content">
                            Antibody <br />
                            test
                          </div>
                        </div>
                        <div className="cell dark">
                          <div className="content">
                            Antibody/ <br />
                            Antigen Test
                          </div>
                        </div>
                        <div className="cell last">
                          <div className="content">
                            Nucleic <br />
                            Acid Test <br />
                            (NAT)
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="testing-chart second">
                      <div className="chart-column">
                        <div className="header dark">
                          Where/Why <br />
                          It is Used
                        </div>
                        <div className="cell first">
                          <div className="content">
                            Most rapid tests and the only approved self-tests
                            are antibody tests.
                          </div>
                        </div>
                        <div className="cell dark">
                          <div className="content">
                            Antibody/antigen tests are recommended for testing
                            done in labs, but there is also a rapid version
                            available.
                          </div>
                        </div>
                        <div className="cell last">
                          <div className="content">
                            This test is used as a first screening for HIV if an
                            individual is believed to have recently had a
                            high-risk exposure and has early symptoms of HIV
                            infection.
                          </div>
                        </div>
                      </div>
                      <div className="chart-column">
                        <div className="header light">Sample Used</div>
                        <div className="cell first">
                          <div className="content">
                            This test can be done using saliva, a finger prick,
                            or blood drawn from a vein.
                          </div>
                        </div>
                        <div className="cell dark">
                          <div className="content">
                            Lab tests must be done using blood drawn from a
                            vein. The rapid version uses a finger prick.
                          </div>
                        </div>
                        <div className="cell last">
                          <div className="content">
                            NATs can be done only with blood drawn from a vein.
                          </div>
                        </div>
                      </div>
                      <div className="chart-column">
                        <div className="header dark">
                          What It
                          <br />
                          Looks For
                        </div>
                        <div className="cell first">
                          <div className="content">
                            HIV antibodies. Antibodies are proteins produced by
                            the immune system when it is exposed to viruses.
                          </div>
                        </div>
                        <div className="cell dark">
                          <div className="content">
                            The p24 antigen and HIV antibodies. Antigens are
                            molecules that stimulate the immune system. In
                            people with HIV, an antigen called p24 is produced
                            even before antibodies develop.
                          </div>
                        </div>
                        <div className="cell last">
                          <div className="content">
                            NATs look for the actual virus in a person’s blood.
                            This test can tell not only if a person is infected
                            with HIV, but can also determine how much virus is
                            in their blood. This test is expensive. It is not
                            usually used for initial diagnosis, but instead to
                            determine viral load of people known to have HIV.
                          </div>
                        </div>
                      </div>
                      <div className="chart-column">
                        <div className="header light">
                          How Soon It Can <br />
                          Detect HIV Infection
                        </div>
                        <div className="cell first">
                          <div className="content">
                            Antibody tests can detect HIV infection between 23
                            and 90 days after exposure. Tests that use blood
                            drawn from a vein can usually detect antibodies
                            sooner than those that use finger pricks or saliva.
                          </div>
                        </div>
                        <div className="cell dark">
                          <div className="content">
                            Antibody/antigen tests done using blood drawn from a
                            vein can usually detect HIV infection between 18 and
                            45 days after exposure. Those tests done using a
                            finger prick can detect infection between 18 and 90
                            days after exposure.
                          </div>
                        </div>
                        <div className="cell last">
                          <div className="content">
                            NATs can usually detect the virus in a person’s
                            blood between 10 and 33 days after exposure.
                          </div>
                        </div>
                      </div>
                      <div className="chart-column">
                        <div className="header dark">
                          How Long <br />
                          Results Take
                        </div>
                        <div className="cell first">
                          <div className="content">
                            Results of rapid antibody tests using saliva or a
                            finger prick can be ready in 30 minutes or less.
                            Results of antibody{" "}
                            <span className="d-inline-block">self-tests</span>{" "}
                            can be ready in 20 minutes.
                          </div>
                        </div>
                        <div className="cell dark">
                          <div className="content">
                            Results of rapid antibody/antigen tests from a
                            finger prick can be ready in 30 minutes or less.
                            Results from tests using blood drawn from a vein
                            need to be processed in a laboratory; this can take
                            several days.
                          </div>
                        </div>
                        <div className="cell last">
                          <div className="content">
                            NATs using blood drawn from a vein need to be
                            processed in a laboratory; this can take several
                            days.
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
              <BackToTop />
            </Col>
          </Row>
        </Container>
        <References page={this.props.page} />
      </div>
    );
  }
}

export default Content;
